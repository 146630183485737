<template>
  <v-dialog
    :value="true"
    width="400px"
    transition="dialog-transition"
    persistent
  >
    <v-card
      v-on-clickaway="closeDialog" class="d-flex flex-column px-6"
    >
      <!-- Title -->
      <v-card-title class="pa-0" style="height: 14px;">
        <v-list-item class="d-flex flex-row justify-end pa-0">
          <v-list-item-action class="ma-0">
            <v-btn icon color="black" @click="$emit('close')">
              <v-icon small color="primary">
                close
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card-title>
      <v-row class="mb-4">
        <v-col v-show="clickedData.category">
          <h3 v-text="$t('project.form.enrollmentPopup.category')" />
          <span v-text="clickedData.category" />
        </v-col>
      </v-row>
      <v-row v-if="user" class="mb-4">
        <v-col>
          <v-row class="px-3">
            <h3 v-text="$t('project.form.enrollmentPopup.player')" />
          </v-row>
          <v-row class="d-flex align-center px-2">
            <v-icon
              color="primary"
            >
              person_outline
            </v-icon>
            <span
              class="ml-2"
              v-text="`${user.firstName} ${user.lastName}`"
            />
          </v-row>
          <v-row class="d-flex align-center px-2">
            <v-icon
              color="primary"
            >
              email
            </v-icon>
            <a
              class="ml-2"
              :href="'mailto:' + user.email"
              v-text="user.email"
            />
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="parent" class="mb-4">
        <v-col>
          <v-row class="px-3">
            <h3 class="font-weight-bold" v-text="$t('project.form.enrollmentPopup.parent')" />
          </v-row>
          <v-row class="d-flex align-center px-2">
            <v-icon
              color="primary"
            >
              person_outline
            </v-icon>
            <span
              class="ml-2"
              v-text="`${parent.firstName} ${parent.lastName}`"
            />
          </v-row>
          <v-row class="d-flex align-center px-2">
            <v-icon
              color="primary"
            >
              email
            </v-icon>
            <a
              class="ml-2"
              :href="'mailto:' + parent.email"
              v-text="parent.email"
            />
          </v-row>
        </v-col>
      </v-row>

      <!-- Content -->
      <v-row v-if="formattedForm.length" class="my-6 px-3">
        <v-col>
          <v-row class="my-2">
            <h3 v-text="$t('project.form.enrollmentPopup.data')" />
          </v-row>
          <!-- Text Fields -->
          <v-row
            v-for="field in formattedForm.filter(field => !['file', 'image'].includes(field.type))"
            :key="formattedForm.map(f => f.label).indexOf(field.label)"
            class="mb-3 px-0"
          >
            <div class="d-flex flex-column" style="max-width: 100%;">
              <span class="font-weight-medium" v-text="field.label" />
              <span v-text="field.value" />
            </div>
          </v-row>
          <!-- Files and Images -->
          <v-row
            v-for="field in formattedForm.filter(field => ['file', 'image'].includes(field.type))"
            :key="formattedForm.map(f => f.label).indexOf(field.label)"
            class="mb-3 px-0"
          >
            <div class="d-flex align-center">
              <v-icon small color="primary" class="mr-2">
                get_app
              </v-icon>
              <a
                target="_blank"
                :href="field.value"
                v-text="field.label"
              />
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { countries } from 'countries-list'
import { DateTime } from 'luxon'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'DynamicFormDialog',
  mixins: [clickaway],
  props: {
    clickedData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDataLoaded: false,
      user: null,
      parent: null,
      dynamicForm: null,
      enrollment: null,
      formattedForm: [],
    }
  },
  computed: {
    formattedFields({ parseField }) {
      return this.$store.state.dynamicForm.dbData?.fields.map(parseField)
    },
  },
  async created() {
    const { organizationId, projectId } = this.$route.params
    const { id, parentId } = this.clickedData
    this.$store.commit('loader/show')

    // user(s) data
    if (id) {
      this.user = await this.$store.dispatch('user/read', id)
    }
    if (parentId) {
      this.parent = await this.$store.dispatch('user/read', parentId)
    }
    // dynamic form data
    this.dynamicForm = await this.$store.dispatch('dynamicForm/readForm', { organizationId, projectId })
    this.formattedForm = this.parseMembership()

    this.isDataLoaded = true
    this.$store.commit('loader/hide')
  },
  methods: {
    parseMembership() {
      const { dynamicForm: { fields }, clickedData: { form }, formatFieldByType } = this
      // 'fields' === array of blank fields from the form document (https://owqlo.atlassian.net/wiki/spaces/OWQLO/pages/1799979017/Dynamic+forms)
      // 'form' === object from the project.enrollments.enrollmentId document { id: value, id: value, etc }
      return form ? fields.map(formValue => {
        const field = Object.entries(form).find(([key]) => key === formValue?.id)
        return formValue?.label && ({
          id: formValue?.id,
          label: formValue?.label ?? '',
          type: formValue?.type ?? '',
          value: field ? formatFieldByType(field, formValue) : '',
        })
      })?.filter(e => e) : []
    },
    formatFieldByType(field, formValue) {
      const [id, userValue] = field // might have .isRequired
      let result
      const { type } = formValue
      switch (type) {
      case 'date':
        result = userValue?.seconds && DateTime.fromSeconds(userValue.seconds).toLocaleString()
        break
      case 'dateObject':
        result = `${userValue?.year ?? ''}-${userValue?.month ?? ''}-${userValue?.day ?? ''}`
        break
      case 'address':
        result = `${userValue?.country ?? ''}, ${userValue?.state ?? ''}, ${userValue?.city ?? ''}, ${userValue?.line1 ?? ''}, ${userValue?.line2 ?? ''}, ${userValue?.zip ?? ''}`
        break
      case 'phone':
        result = `+${userValue?.code ?? ''} ${userValue?.number ?? ''}`
        break
      case 'boolean':
        result = userValue ? '✅' : '❌'
        break
      case 'switch':
        result = userValue ? '✅' : '❌'
        break
      case 'checkbox':
        result = userValue ? '✅' : '❌'
        break
      case 'select':
        if (formValue.optionsType === 'countries') result = countries[userValue]?.name
        if (formValue.optionsType === 'custom') result = formValue.options.find(option => option.id === userValue)?.label
        // if (field.optionsType === 'languages') TODO: NO MVP https://owqlo.atlassian.net/browse/OW-2912
        break
      case 'radio':
        result = formValue?.options.find(option => option.id === userValue)?.label ?? ''
        break
      default:
        result = userValue
        break
      }
      return result
    },
    closeDialog() { this.$emit('close') },
  },
}
</script>
